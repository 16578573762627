<template lang="pug">
  .mobile-menu
    .mobile-menu__user
      h2.mobile-menu__user-name {{ user.name }}
      p.mobile-menu__user-position {{ types[user.user.role] | translate }}
    .mobile-menu__wrapper
      ul.mobile-menu__list
        li(v-for="item in options"
          :key="item.id"
          @click="closeMobileMenu")
          router-link.mobile-menu__item(
            :to="item.path"
            :class="{ 'mobile-menu__item--active': $route.path.includes(item.path) }")
            .mobile-menu__item-icon
              icon-base(width="25" height="25")
                component(
                  :is="item.icon")
            span {{ item.title | translate }}
      button(
        @click="logout").btn.btn--big.btn--outline.btn--white.btn--fix-width.mobile-menu__button {{ 'actions.logout' | translate }}
      //- .mobile-menu__group-icons
      //-   .mobile-menu__group-wrap
      //-     i.i-pig.mr-2.animation-move
      //-     i.i-flower-sm.mr-2.animation-rotation
      //-     i.i-flower.mr-2.animation-rotation
      //-     i.i-flower-sm.mr-2.animation-rotation
      //-     i.i-flower.animation-rotation
      //-   .mobile-menu__group-line
      //-     i.i-line
</template>

<script>
import IconBase from '@/components/icons/IconBase'
import { USER_TYPES } from '@/util/consts'

import menuMixin from '@/mixins/menu.mixin.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MobileMenu',

  mixins: [menuMixin],

  components: {
    IconBase
  },

  data: () => ({
    types: USER_TYPES
  }),

  computed: {
    ...mapGetters([
      'user'
    ])
  },

  methods: {
    ...mapActions([
      'closeMobileMenu'
    ]),

    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .mobile-menu {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100vh;
    background-color: $color-brand;

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 4rem;
    }

    &__user {
      margin-bottom: 4rem;
      padding: 2.6rem 1.6rem 4rem 5.4rem;
      clip-path: polygon(0 0, 100% 0, 100% 46%, 18% 100%, 0 87%);
      background-color: rgba($color-white, .2);

      &-name {
        margin-bottom: .2rem;
        font-size: 1.8rem;
        color: $color-white;
        font-weight: 700;
      }

      &-position {
        font-size: 1.4rem;
        color: $color-white;
      }
    }

    &__list {
      margin-bottom: 3rem;
      padding-bottom: 3rem;
      max-width: 25rem;
      border-bottom: .1rem solid $color-white;
    }

    &__item {
      color: $color-white;
      font-size: 1.7rem;
      font-weight: 300;
      padding: 1.2rem 1.6rem;
      height: 5.5rem;
      width: 25rem;
      display: flex;
      align-items: center;

      &--active {
        border-radius: 1rem;
        background-color: rgba($color-white, .15);
      }
    }

    &__item-icon {
      margin-right: 2rem;
    }

    &__button {
      margin-bottom: 8rem;
    }

    &__group-wrap {
      position: absolute;
      bottom: 2rem;
    }

    &__group-line {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
</style>
